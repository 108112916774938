import { MeasurementUnits, QuoteTypeOfBuy } from '../enums';
import { isSameMonthOrAfter } from '../utils';
import { QUOTE_PRODUCT_MAX_QUANTITY } from '../mappings';
import { Inventory } from '../types/Inventory';
import {
  getQuantityErrorMessage,
  isQuoteProductMarketValid,
} from '../utils/quote';
import { Cable, QuoteProduct } from '../models';
import yup from './yup';

const REQUIRED_FIELD = 'common.validations.fieldRequired';

const getQuoteSchema = (markets: Inventory[], units: MeasurementUnits) => {
  const submit = {
    shippingLocation: yup.string().nullable().required(REQUIRED_FIELD),
    typeOfBuy: yup.string().nullable().required(REQUIRED_FIELD),
    companyId: yup.string().nullable().required(REQUIRED_FIELD),
    customerId: yup.string().when('customerName', {
      is: (customerName: string) => !!customerName,
      then: yup.string().nullable().notRequired(),
      otherwise: yup.string().nullable().required(REQUIRED_FIELD),
    }),
    shippingYearMonth: yup.mixed().when('typeOfBuy', {
      is: (t: QuoteTypeOfBuy) => t === QuoteTypeOfBuy.InventoryBuy,
      then: yup.mixed().nullable().notRequired(),
      otherwise: yup
        .mixed()
        .test(
          'min-date',
          'quotes.edit.summary.quoteDetails.shippingYearMonth.minDate',
          (value) => isSameMonthOrAfter(value)
        )
        .required(REQUIRED_FIELD),
    }),
    projectName: yup.string().when('typeOfBuy', {
      is: (t: QuoteTypeOfBuy) =>
        !!t &&
        [QuoteTypeOfBuy.ProjectBuy, QuoteTypeOfBuy.ProjectBid].includes(t),
      then: yup.string().nullable().required(REQUIRED_FIELD),
      otherwise: yup.string().nullable().notRequired(),
    }),
    products: yup.array().of(
      yup
        .object()
        .shape({
          quantity: yup
            .number()
            .min(1)
            .max(QUOTE_PRODUCT_MAX_QUANTITY)
            .required(REQUIRED_FIELD),
        })
        .test(
          'isQuoteProductMarketValid',
          'quotes.edit.products.table.validation.invalidMarket',
          (value) =>
            isQuoteProductMarketValid(value as unknown as QuoteProduct, markets)
        )
        .test(
          'isQuantityIncrement',
          'quotes.edit.products.table.validation.invalidMultiplierGeneric',
          (value) => {
            const product = value as unknown as QuoteProduct;
            return !getQuantityErrorMessage(
              product.quantity,
              product as Cable,
              units
            );
          }
        )
    ),
  };
  const submitSchema = yup.object().shape(submit);
  const pricingSchema = yup.object().shape({
    ...submit,
    products: yup.array().of(
      yup
        .object()
        .shape({
          quantity: yup
            .number()
            .min(1, 'quotes.edit.products.table.validation.emptyQuantity')
            .max(QUOTE_PRODUCT_MAX_QUANTITY)
            .required(REQUIRED_FIELD),
          price: yup
            .number()
            .min(1, 'quotes.edit.products.table.validation.emptyPrice')
            .max(QUOTE_PRODUCT_MAX_QUANTITY)
            .required(REQUIRED_FIELD),
          minimumOrderQuantity: yup
            .number()
            .min(0)
            .max(QUOTE_PRODUCT_MAX_QUANTITY)
            .nullable()
            .notRequired(),
          discount: yup.number().min(0).max(100).nullable().notRequired(),
          description: yup.string().required(REQUIRED_FIELD),
        })
        .test(
          'isQuoteProductMarketValid',
          'quotes.edit.products.table.validation.invalidMarket',
          (value) =>
            isQuoteProductMarketValid(value as unknown as QuoteProduct, markets)
        )
        .test(
          'isQuantityIncrement',
          'quotes.edit.products.table.validation.invalidMultiplierGeneric',
          (value) => {
            const product = value as unknown as QuoteProduct;
            return !getQuantityErrorMessage(
              product.quantity,
              product as Cable,
              units
            );
          }
        )
    ),
    expirationTimeOption: yup.string().nullable().required(REQUIRED_FIELD),
  });
  return { submitSchema, pricingSchema };
};

export default getQuoteSchema;
